import { t } from "i18next";
import {
  Api,
  DataResponse,
  SentryReporter,
  TokenManager,
} from "imagine-essentials";
import {
  Email,
  EmailSearchFilter,
  Preferences,
  UsedFeature,
  UsedFeaturesSearchFilter,
  User,
  UserSearchFilter,
} from "..";
import { UserFields } from "../types/UserFields";
import { Country, Language } from "imagine-i18n";

const getLoggedInUser = async () => {
  // No reason to request user data if no token is stored
  if (!TokenManager.hasTokens()) {
    return { success: false };
  }
  const result = await Api.get("api/users/info");
  console.log("Get logged in user", result);
  const response: DataResponse<{ user: User; preferences: Preferences }> = {
    success: result.success,
    apiVersion: result.version,
  };
  // Possible errors
  // 403: Access denied
  // 500: Server error
  if (result.success) {
    if (result.data.id === undefined) {
      console.error("Check database for users");
    }
    const user = {
      id: result.data.id,
      email: result.data.email,
      language: result.data.language,
      country: result.data.country,
      role: result.data.role,
      name: result.data.name,
    } as User;
    if (result.data.tokens !== undefined) {
      const tokens = result.data.tokens;
      TokenManager.saveTokens(tokens, true);
    }
    try {
      let preferences: Preferences = {};
      if (result.data.preferences) {
        preferences = JSON.parse(result.data.preferences) as Preferences;
      }
      response.data = { user, preferences };
    } catch (e) {
      console.error(e);
      SentryReporter.captureException("Failed to parse user preferences", {
        "Server response": JSON.stringify(result),
      });
    }

    // return result.data as User;
  } else {
    if (result.status === 403) {
      // User is not logged in
    } else {
      console.error("Unexpected error when checking logged in user");
      console.error(result);
      SentryReporter.captureException(
        "Unexpected error when checking logged in user"
      );
    }
  }
  return response;
};

/**
 * Send log in request and saves returned JWT token if login succeeded. Returns user on success.
 */
const logIn = async (email: string, password: string, rememberMe: boolean) => {
  const data = {
    email: email,
    password: password,
    remember: rememberMe, // Not used for jwt, for kept for legacy reasons
  };

  const result = await Api.post("api/users/login", data);
  const response: DataResponse<{ user: User; preferences: Preferences }> = {
    success: result.success,
    apiVersion: result.version,
  };
  console.warn(result);
  // Possible errors
  // 401: Login failed
  // 500: Server error
  // 400: Missing data (email or password)
  if (result.success && result.data) {
    // user.setUser(result.data);

    if (result.data.tokens !== undefined) {
      const tokens = result.data.tokens;
      TokenManager.saveTokens(tokens, rememberMe);
    }

    response.data = {
      user: {
        country: result.data.country,
        email: result.data.email,
        id: result.data.id,
        language: result.data.language,
        name: result.data.name,
        role: result.data.role,
      },
      preferences: result.data.preferences,
    };
  } else {
    if (result.status === 401) {
      response.errorMessage = t("users:loginFailed");
      response.errorCode = "failed";
    } else if (result.status === 409) {
      response.errorMessage = t("users:loginFailedMissingActivation");
      response.errorCode = "not-activated";
    } else {
      console.error(result);
      SentryReporter.captureException("Login error", {
        "Server response": JSON.stringify(result),
      });
      response.errorMessage = t("users:loginFailedServerError");
      response.errorCode = "failed";
    }
  }
  return response;
};

const logOut = () => {
  TokenManager.clearTokens();
};

const resetPassword = async (email: string) => {
  const data = {
    email: email,
  };

  const result = await Api.post("api/users/request-reset-password", data);
  // Possible errors
  // 409: Reset failed, account not activated
  // 500: Server error
  // 400: Missing data (email or password)
  if (result.success) {
    return {
      success: true,
    };
  } else {
    if (result.status === 409) {
      return {
        errorMessage: t("users:resetFailedMissingActivation"),
        error: "not-activated",
      };
    } else {
      console.error(result);
      SentryReporter.captureException("Reset password error", {
        "Server response": JSON.stringify(result),
      });
      return {
        errorMessage: t("users:resetPasswordFailed"),
        error: "failed",
      };
    }
  }
};

const resendActivationEmail = async (email: string) => {
  const data = {
    email: email,
  };

  const result = await Api.post(
    "api/users/resend-activation-email/" + email,
    data
  );
  // Possible errors
  // 404: Email does not exist
  // 409: Already activated
  // 500: Unable to send email
  // 400: Missing data (email)

  if (result.success) {
    // setMessage(t("users:activationEmailSent"));
    return {
      error: "",
    };
  } else {
    if (result.status === 409 || result.status === 404) {
      return {
        errorMessage: t("users:emailDoesNotExistOrAlreadyActivated"),
        error: "not-exist",
      };
    } else {
      console.error(result);
      SentryReporter.captureException("Resend activation email error", {
        "Server response": JSON.stringify(result),
      });
      return {
        errorMessage: t("users:failedToResendActivationEmail"),
        error: "failed",
      };
    }
  }
};

const signUp = async (
  email: string,
  password: string,
  country?: Country,
  language?: Language
) => {
  const data = {
    email: email,
    password: password,
    source: localStorage.getItem("source"),
    country: country,
    language: language,
  };
  const result = await Api.post("api/users/signup", data);
  // Possible errors
  // 403: User (email) already exists
  // 422: Invalid data (email format, password format)
  // 500: Server error
  // 400: Missing data (email or password)
  if (result.success) {
    return { success: true };
  } else {
    if (result.status === 409) {
      return { emailErrorMessage: t("users:emailAlreadyExists") };
    } else if (result.status === 422) {
      if (result.data.email === false) {
        return { emailErrorMessage: t("users:invalidEmailFormat") };
      }
      if (result.data.password === false) {
        return { passwordErrorMessage: t("users:passwordMin6Characters") };
      }
      SentryReporter.captureException("Signup response error", {
        "Server response": JSON.stringify(result),
      });
      return { errorMessage: t("users:signUpServerError") };
    } else {
      console.error(result);
      SentryReporter.captureException("Signup error", {
        "Server response": JSON.stringify(result),
      });
      return { errorMessage: t("users:signUpServerError") };
    }
  }
};

const updateUserProfile = async (
  email: string,
  name: string,
  language: string,
  country: string
) => {
  const data = {
    email: email,
    name: name,
    language: language,
    country: country,
  };

  const result = await Api.post("api/users/update", data);
  const response: DataResponse<User> = {
    success: result.success,
  };

  // Possible errors
  // 500: Server error
  if (result.success) {
    if (result.data.tokens !== undefined) {
      const tokens = result.data.tokens;
      TokenManager.saveTokens(tokens);
      response.data = result.data.user;
    }
  } else {
    if (result.status === 422) {
      response.errorMessage = t("users:invalidEmailFormat");
    } else if (result.status === 403) {
      response.errorMessage = t("users:emailAlreadyExists");
    } else {
      SentryReporter.captureException("Failed to save updated user data", {
        "Server response": JSON.stringify(result),
        "User data": data,
      });
      response.errorMessage = t("users:userUpdateFailed");
    }
  }
  return response;
};

const updatePassword = async (currentPassword: string, newPassword: string) => {
  const data = {
    currentPassword: currentPassword,
    newPassword: newPassword,
  };

  const result = await Api.post("api/users/update-password", data);
  console.warn(result);
  // Possible errors
  // 500: Server error
  if (result.success) {
    return {
      successMessage: t("users:passwordWasChanged"),
    };
  } else {
    SentryReporter.captureException("Failed to reset password", {
      "Server response": JSON.stringify(result),
    });
    return {
      errorMessage: t("users:currentPasswordInvalid"),
    };
  }
};

/**
 * Update the user preferences
 * @param preferences The preferences object. No type is given, because this is project specific.
 * @returns True on success.
 */
const updatePreferences = async (preferences: Preferences) => {
  const result = await Api.post("api/users/preferences", preferences);
  const response: DataResponse<void> = {
    success: result.success,
  };
  if (!result.success) {
    SentryReporter.captureException("Failed to update user preferences", {
      result: JSON.stringify(result),
    });
    response.errorMessage = t("users:failedToUpdatePreferences");
  }
  return response;
};

/**
 * Get the user preferences.
 * @returns The preference object on success and undefined on failure.
 */
const getPreferences = async () => {
  const result = await Api.get("api/users/preferences");
  if (!result.success) {
    SentryReporter.captureException("Failed to get user preferences", {
      result: JSON.stringify(result),
    });
    return undefined;
  }
  return result.data;
};

const restorePassword = async (password: string, resetCode: string) => {
  const data = {
    password: password,
    resetCode: resetCode,
  };

  const result = await Api.post("api/users/reset-password", data);
  const response: DataResponse<void> = {
    success: result.success,
  };
  // Possible errors
  // 500: Server error
  if (!result.success) {
    if (result.status === 401) {
      // Expired or invalid code
      response.errorMessage = t("users:passwordNotRestoredTimeExpired");
    } else if (result.status === 422) {
      // Expired or invalid code
      response.errorMessage = t("users:passwordMin6Characters");
    } else {
      // Server error
      response.errorMessage = t("users:passwordNotRestoredServerError");
      SentryReporter.captureException(
        "Failed to restore password due to server error",
        {
          "Server response": JSON.stringify(result),
        }
      );
    }
  }
  return response;
};

const activateAccount = async (activationCode: string) => {
  const data = {
    activationCode: activationCode,
  };
  const result = await Api.post("api/users/activate", data);
  const response: DataResponse<void> = {
    success: result.success,
  };
  // Possible errors
  // 500: Server error
  if (!result.success) {
    if (result.status === 422) {
      // Expired or invalid code
      response.errorMessage = t("users:activationFailedInvalidActivationCode");
    } else {
      // Server error
      response.errorMessage = t("users:activationFailed");
      SentryReporter.captureException("Failed to activate account", {
        "Activation code": activationCode,
        "Server response": JSON.stringify(result),
      });
    }
  }
  return response;
};

const postUsedFeatures = async (userId: number, features: number[]) => {
  const result = await Api.post("api/users/log-features/" + userId, features);
  const response: DataResponse<number[]> = {
    success: result.success,
    apiVersion: result.version,
  };
  if (!result.success) {
    SentryReporter.captureException("Failed to log features", {
      "Server response": JSON.stringify(result),
    });
    response.data = features;
  }
  return response;
};

const getUserOptions = async () => {
  const result = await Api.get("api/users/options");
  const response: DataResponse<User[]> = {
    success: result.success,
  };
  if (result.success && result.data) {
    response.data = result.data;
  } else {
    SentryReporter.captureException("Failed to get user options", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

const searchUsers = async (filter: UserSearchFilter) => {
  const cleanFilter: UserSearchFilter = {
    email: filter.email || undefined,
    country: filter.country || undefined,
    role:
      filter.role === undefined
        ? undefined
        : filter.role > 0
        ? filter.role
        : undefined,
    active: filter.active || undefined,
    sortBy: filter.sortBy || undefined,
    language: filter.language || undefined,
  };
  const result = await Api.post("api/users/search", cleanFilter);
  const response: DataResponse<User[]> = {
    success: result.success,
  };
  if (result.success && result.data) {
    response.data = result.data;
  } else {
    SentryReporter.captureException("Failed to search users", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

const updateUser = async (userId: number, user: UserFields) => {
  const result = await Api.post("api/users/update/admin/" + userId, user);
  const response: DataResponse<void> = {
    success: result.success,
  };
  if (!result.success) {
    SentryReporter.captureException("Failed to update user", {
      "Server response": JSON.stringify(result),
    });
    response.errorMessage = t("users:updateUserFailed");
  }
  return response;
};

const voteCountryExpansion = async (country: string, ip: string) => {
  if (country === "" || ip === "") return;
  const result = await Api.post("api/users/vote-country-expansion", {
    country: country,
    ip: ip,
  });
  const response: DataResponse<void> = {
    success: result.success,
  };
  if (!result.success) {
    SentryReporter.captureException("Failed to vote country expansion", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

const getEmails = async (filter: EmailSearchFilter) => {
  const result = await Api.post("api/users/emails", filter);
  const response: DataResponse<Email[]> = {
    success: result.success,
  };
  if (result.success && result.data) {
    response.data = result.data;
  } else {
    SentryReporter.captureException("Failed to get emails", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

const deleteUnactivatedUsers = async () => {
  const result = await Api.post("api/users/remove-unactivated", {});
  const response: DataResponse<void> = {
    success: result.success,
  };
  if (!result.success) {
    SentryReporter.captureException("Failed to delete unactivated users", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

/**
 * Deletes all plans, plant collections, private plants and private object for a user. The user itself is marked as deleted.
 * @param userId
 * @returns
 */
const deleteUser = async (userId: number) => {
  const result = await Api.post("api/users/delete/" + userId, {});
  const response: DataResponse<{ errors: number }> = {
    success: result.success,
  };
  console.log("Delete user", result);
  if (result.success && result.data) {
    response.data = result.data;
  }
  if (!result.success) {
    SentryReporter.captureException("Failed to delete user", {
      "Server response": JSON.stringify(result),
    });
  }

  return response;
};

const getUsedFeatures = async (filter: UsedFeaturesSearchFilter) => {
  const result = await Api.post("api/users/used-features", filter);
  const response: DataResponse<UsedFeature[]> = {
    success: result.success,
  };
  if (result.success && result.data) {
    response.data = result.data;
  } else {
    SentryReporter.captureException("Failed to get used features", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

export const UsersApi = {
  getLoggedInUser,
  updatePreferences,
  getPreferences,
  logIn,
  logOut,
  resetPassword,
  resendActivationEmail,
  signUp,
  updateUserProfile,
  updatePassword,
  restorePassword,
  activateAccount,
  postUsedFeatures,
  getUserOptions,
  searchUsers,
  updateUser,
  voteCountryExpansion,
  getEmails,
  deleteUnactivatedUsers,
  deleteUser,
  getUsedFeatures,
};
