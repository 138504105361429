import { Tokens } from "..";

/**
 * Get a token from either local storage or session storage. Will check local storage first, and if it does not exist it will check session storage. Returns null if the key does not exist.
 * @param key The key to search for.
 */
export const getToken = (key: string) => {
  if (localStorage.getItem(key) !== null) return localStorage.getItem(key);
  else return sessionStorage.getItem(key);
};

/**
 * Checks if the user has tokens stored. The token might be outdated even if this returns true.
 */
export const hasTokens = () => {
  return getToken("accessToken") !== null && getToken("refreshToken") !== null;
};

/**
 * Save tokens in browser
 * @param tokens The access- and refresh tokens.
 * @param remember True if user should stay logged in, false if user should be logged out when browser closes and undefined to use existing configuration (will default to false if no configuration exists currently)
 */
export const saveTokens = (tokens: Tokens, remember?: boolean | undefined) => {
  if (remember === undefined) {
    if (localStorage.getItem("accessToken") !== null) remember = true;
    else remember = false;
  }
  if (remember) {
    localStorage.setItem("accessToken", tokens.accessToken);
    localStorage.setItem("refreshToken", tokens.refreshToken);
  } else {
    sessionStorage.setItem("accessToken", tokens.accessToken);
    sessionStorage.setItem("refreshToken", tokens.refreshToken);
  }
};

export const clearTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  sessionStorage.removeItem("accessToken");
  sessionStorage.removeItem("refreshToken");
};

/**
 * If tokens are set in session storage, this will also set them in local storage.
 */
export const forceRememberMe = () => {
  const accessToken = sessionStorage.getItem("accessToken");
  const refreshToken = sessionStorage.getItem("refreshToken");
  if (accessToken !== null && refreshToken !== null) {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
  }
};

export const TokenManager = {
  getToken,
  hasTokens,
  saveTokens,
  clearTokens,
  forceRememberMe,
};
