import { DateFilter, DateRange, DateTimeFormat, DateTimeText } from "..";

/**
 * Get a Date object based on a date string in db format. If the string is empty it returns date object of today.
 */
const getDateFromDbFormat = (dbDate: string) => {
  const parts = dbDate.split("-");
  if (parts.length > 2) {
    return new Date(
      parseInt(parts[0]),
      parseInt(parts[1]) - 1,
      parseInt(parts[2])
    );
  }
  return new Date();
};

/**
 * Checks if date 1 is before date 2.
 */
const isDateBefore = (date1: string, date2: string) => {
  const d1 = getDateFromDbFormat(date1);
  const d2 = getDateFromDbFormat(date2);
  return d1.getTime() < d2.getTime();
};

const getDateFilterFromRange = (dateRange: DateRange) => {
  const now = Date.now();
  let diff = 0;
  switch (dateRange) {
    case DateRange.LAST_7_DAYS:
      diff = 6;
      break;
    case DateRange.LAST_30_DAYS:
      diff = 29;
      break;
    case DateRange.LAST_3_MONTHS:
      diff = Math.round(364 / 4);
      break;
    case DateRange.LAST_6_MONTHS:
      diff = Math.round(364 / 2);
      break;
    case DateRange.LAST_YEAR:
      diff = 364;
      break;
    case DateRange.NONE:
      return {
        startDate: undefined,
        endDate: undefined,
      };
    default:
      diff = 0;
      break;
  }
  const startDate = new Date(now - 3600 * 1000 * 24 * diff);
  return {
    startDate: DateTimeText.getDateTimeText(
      startDate,
      DateTimeFormat.BACKEND_DATE
    ),
    endDate: DateTimeText.getDateTimeText(
      new Date(now),
      DateTimeFormat.BACKEND_DATE
    ),
  } as DateFilter;
};

export const DateTimeTools = {
  getDateFromDbFormat,
  isDateBefore,
  getDateFilterFromRange,
};
