import { Delimiter, Tools } from "imagine-essentials";
import { Country, Currency, CurrencyPlacement } from "..";

const getCountryCurrency = (country: Country) => {
  switch (country) {
    case "DK":
      return "DKK";
    case "GB":
      return "GBP";
    case "IE":
      return "EUR";
    default:
      throw new Error("Country not implemented: " + country);
  }
};

const getCurrencyDisplayUnit = (currency: Currency) => {
  switch (currency) {
    case "DKK":
      return "kr.";
    case "GBP":
      return "£";
    case "EUR":
      return "€";
    default:
      throw new Error("Currency not implemented: " + currency);
  }
};

const getDelimiter = (currency: Currency): Delimiter => {
  switch (currency) {
    case "DKK":
      return ",";
    case "GBP":
      return ".";
    case "EUR":
      return ".";
    default:
      throw new Error("Currency not implemented: " + currency);
  }
};

const getDefaultCurrencyPlacement = (currency: Currency) => {
  switch (currency) {
    case "DKK":
      return CurrencyPlacement.AFTER;
    case "GBP":
      return CurrencyPlacement.BEFORE;
    case "EUR":
      return CurrencyPlacement.BEFORE;
    default:
      throw new Error("Currency not implemented: " + currency);
  }
};

/**
 * Converts a minor value to a string. The string will always consist of 2
 * numbers, so zero might be prepended. If number is larger than 99, then it
 * will be "rounded" to 2 decimals
 */
const getMinorString = (value: number) => {
  const roundedValue = Tools.round(value, 2);
  const major =
    roundedValue > 0 ? Math.floor(roundedValue) : Math.ceil(roundedValue);
  const minor = Math.round(Math.abs(roundedValue - major) * 100);
  if (minor < 10) return "0" + Math.round(minor);
  return Math.round(minor).toString();
};

const getMajorString = (value: number) => {
  const major = value > 0 ? Math.floor(value) : Math.ceil(value);
  return major.toString();
};
/**
 * Generates a string to display a price correctly.
 * @param price The price (amount) as a whole number
 * @param currency The currency code
 * @param forceMinor True if it should always display minor amount after. If it has no minor, then 00 is displayed.
 * @param displayCurrency Set to override the default placement for the currency
 */
const getDisplayPrice = (
  price: number,
  currency: Currency,
  forceMinor: boolean,
  displayCurrency?: CurrencyPlacement
) => {
  let priceString = getMajorString(price);
  const minor = getMinorString(price);
  if (forceMinor || minor !== "00") {
    priceString += getDelimiter(currency) + minor;
  }

  const placement =
    displayCurrency === undefined
      ? getDefaultCurrencyPlacement(currency)
      : displayCurrency;

  switch (currency) {
    case "DKK":
      if (placement === CurrencyPlacement.BEFORE) {
        return getCurrencyDisplayUnit(currency) + " " + priceString;
      } else if (placement === CurrencyPlacement.AFTER) {
        return priceString + " " + getCurrencyDisplayUnit(currency);
      } else {
        return priceString;
      }
    case "GBP":
    case "EUR":
      if (placement === CurrencyPlacement.BEFORE) {
        return getCurrencyDisplayUnit(currency) + priceString;
      } else if (placement === CurrencyPlacement.AFTER) {
        return priceString + getCurrencyDisplayUnit(currency);
      } else {
        return priceString;
      }
    default:
      throw new Error("Currency not implemented: " + currency);
  }

  // if (placement === CurrencyPlacement.BEFORE) {
  //   return getCurrencyDisplayUnit(currency) + " " + priceString;
  // } else if (placement === CurrencyPlacement.AFTER) {
  //   return priceString + " " + getCurrencyDisplayUnit(currency);
  // } else {
  //   return priceString;
  // }
};

export const CurrencyTools = {
  getCountryCurrency,
  getDisplayPrice,
  getCurrencyDisplayUnit,
  getDefaultCurrencyPlacement,
};
